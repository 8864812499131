@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Space-Grotesk:wght@400;500;600;700;800;900&display=swap'); */

/* declare variables here */
:root {
  --elevation-blue: #0052cb;
  --elevation-white: #ffffff;
  --elevation-black: #000000;
  --elevation-dark-grey: #58595b;
  --elevation-light-grey: #dbdcdd;
  --elevation-dark-blue: #06285e;
  --elevation-light-blue: #80c1ff;
  --responsive-width: 50rem;
  --elevation-pink: #D47B7D;
  --elevation-red: #AA2b3e;
  --elevation-dark-red: #663737;
  --elevation-green: #81D553;
  --elevation-yellow: #FF8C00;
  --elevation-dark-yellow: #9f9b34;
  --font-size-xxsm: 0.50rem;   /* 8px */
  --font-size-xsm: 0.625rem;   /* 10px */
  --font-size-sm: 0.75rem;   /* 12px */
  --font-size-base: 1rem;    /* 16px, base */
  --font-size-md: 1.25rem;   /* 20px */
  --font-size-lg: 1.5rem;    /* 24px */
  --font-size-xl: 1.75rem;   /* 28px */
  --font-size-xxl: 2rem;     /* 32px */
  --font-size-xxxl: 2.25rem; /* 36px */
  --skinny-border: 0.0625rem; /* 1px */
  /* smoke a cigerette and use --skinny-border with multipliers when needing 1px as a unit */
}

.green {
  color: var(--elevation-green);
}
.red {
  color: var(--elevation-red);
}
.blue {
  color: var(--elevation-light-blue);
}
.yellow {
  color: var(--elevation-yellow);
}


body {
  font-family: "Inter", "Lato", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Space Grotesk", sans-serif;
}

.App {
  margin: auto;
  width: 100%;
}
.error {
  color: var(--elevation-red);
}

/* small screens */
@media only screen and (max-width: 50rem) {
  .row {
    display: flex;
  }
}
/* big screens */
@media only screen and (min-width: 50rem) {
  .row {
    display: flex;
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

@import url('loadingScreen.css');
@import url('header.css');
@import url('logo.css');
@import url('csvModal.css');
@import url('widgets.css');
@import url('liveDataWidget.css');
@import url('liveConsumptionWidget.css');
@import url('historicalWidget.css');
@import url('dateRangePicker.css');
@import url('insights.css');

.live-consumption-widget-container {
  margin-top: 2.3rem;
}

.live-consumption-segment {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.live-consumption-label {
  color: #0052ca;
  font-size: 0.9rem;
}

.live-consumption-bar {
  background: #96D465;
  height: 18px;
  float: left;
  margin-right: 0.5rem;
  border-radius: 9px;
}
.live-consumption-widget-container .erroring-widget {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 20%;
}

.live-consumption-segments {
  margin-left: 1rem;
  margin-top: 4rem;
}

.live-consumption-bar.negative-consumption {
  background: #f00;
}
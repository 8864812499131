.dateRangePicker {
    margin-top: .5rem;
}

.dateRangePicker span {
    padding-left: 1rem;
    opacity: 50%;
    cursor: pointer;
}

.dateRangePicker .highlight {
   opacity: 100%;
   cursor: default;
}
.dateRangePicker .highlight {
    cursor: default;
  }
.dateRangePicker .highlight.range-back, .dateRangePicker .highlight.range-forward {
    cursor: pointer;
  }


.download-csv-modal {
  background-color: rgba(0,0,0,0.5);
  z-index:10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.download-csv-modal .modal-box {
    z-index:1000;
    border-radius: calc(var(--skinny-border) * 5);
    color: var(--elevation-dark-blue);
    max-width: calc(var(--skinny-border) * 300);
    text-align: center;
    background-color: var(--elevation-white);
    margin: var(--font-size-md) auto;
    box-shadow: 0 0 calc(var(--skinny-border) * 50) rgba(0,0,0,.9);
    padding: var(--font-size-md);
    inset: 50% auto auto 50%;
}
.download-csv-modal .modal-box p {
  margin: 0.7rem 0 2rem;
}


.download-csv-modal button, .download-csv-modal input[type=submit] {
  background-color: var(--elevation-blue);
  color: white;
  padding: 5px;
  font-weight: bold;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.download-csv-modal button:hover, .download-csv-modal input[type=submit]:hover {
  background-color: var(--elevation-dark-blue);
  
}
 
 .button-row button {
   flex:1;
   margin: 10px 15px;
 } 
 
 .header {
   width: 100%;
   height: 64px;
   
 }
 .logo-header {
   max-width:300px; 
   margin: 10px auto;
   /* transition: all 1.0s ease-in-out; */
 }
 .header {
   transition: all 1.0s ease-in-out;
 }
 
 .alignHeader {
  justify-content: space-between;
   width: 100%;
   margin: auto;
 }
 
 img.moveLogo {
   transition: all;
   margin: 0px 0 0 0;
   height: 50px;
 }
 .react-tabs {
 }
 
 .reveal {
   opacity: 0;
   transition: all 1.0s ease-in-out;
 }
 .reveal.now {
   opacity: 1;
 }
 .main-menu {
   background-color: var(--elevation-dark-blue);
   text-align: left;
   overflow: hidden;
   margin-top: -2px;
   max-height: 100px;
   height: auto;
   transition: max-height 1.0s ease-in-out;
   border-radius: 5px 0 5px 5px;
   z-index: 1;
 }
 .main-menu.closeMenu {
   max-height: 0px;
 }
 .main-menu li:hover, .main-menu li a:hover {
   color:  var(--elevation-light-blue);
 }
 .main-menu ul {
  padding-inline-start: 0px;
 }
 .main-menu li {
   text-align: right;
   color: var(--elevation-white); 
   display: block;
   text-decoration: none;
   list-style-type: none;
   position: relative;
   left: -10px;
   cursor: pointer;
   font-size: 0.8rem;
   margin-bottom: 5px;
 }
 .main-menu li a {
   text-align: left;
   color: var(--elevation-white);
   text-decoration: none;
   margin-bottom: 0;
   
 }
 .active.menubutton {
   background-color: var(--elevation-dark-blue);
   border-radius: 5px 5px 0 0;
 }
 button.menubutton {
   transition: background-color 1.0s ease-in-out;
 }
 
 #menuContainer {
   display: flex;
   flex-direction: column;
   align-items: end;
   height: 300px;
   margin-bottom: -300px;
 }
 .active.menubutton{
   color:var(--elevation-white);
   transform: rotate(90deg);
   border-radius: 5px 0px 0px 5px;
   margin-right:2px;
 }
 .menubutton {
   transition: all 1.0s ease-in-out;
   padding: 10px;
   color:var(--elevation-blue);
 }


.locationDropdown {
  margin: 10px auto;
  z-index: 10;
  width:100%;
}
.align-logo-and-dropdown {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.align-logo-and-dropdown > *:first-child {
  margin-right: 2rem;
}

.location-dropdown-wrapper:before {
  content: " ";
  /* border-left: 1px solid var(--elevation-blue); */
  position: absolute;
  height: 1.7rem;
  margin-top: 0.95rem;
  z-index: 99;
  margin-right: 3rem;
}

/* small screens */
@media only screen and (max-width: 50rem) {
  .locationDropdown {
    padding-left: 0rem;
  }
  .small-screen-push-down {
    margin-top: 6rem;
  }
}
/* big screens */
@media only screen and (min-width: 50rem) {
  .locationDropdown {
    padding-left: 2rem;
  }
  .small-screen-push-down {
    margin-top: inherit;
  }
}

.live-left {
  height: 48px;
  width: 100%;
  background: #2151c3;
  color: #fff;
}
.monthly-bill {
  margin-top: 0.8rem;
font-size: 1.4rem;
}
.monthly-bill-tag {
  font-size: 1rem;
}
.live-right {
  height: 48px;
  overflow: hidden;
  padding-right: 1rem;
}

.flexEnd {
  justify-content: flex-end;
}
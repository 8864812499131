#historicalWrapper {
  height: 800px;
  width: 100%;
  margin-top: 0.3rem;
  position: relative;
}

#historicalWrapper .widget-occupier {
  margin: 0;
  padding: 0;
}
#historicalChart {
  z-index: -1;
  transition: opacity 1.0s ease-in-out;
  opacity: 1;
}
#historicalTooltip {
  position: absolute;
  opacity: 0;
}
#historicalWrapper .loading-widget {
  position: fixed;
  width: 100%;
  height: 600px;
  z-index: -1;
  padding-top: 15%;
  padding-bottom: 15%;
}
#historicalWrapper .error-widget {
  position: fixed;
  width: 100%;
  height: 600px;
  z-index: -1;
  padding-top: 15%;
  padding-bottom: 15%;
}

#historyChart.hide-this {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
}
.unitPicker{
  padding-right: 2rem;
}
.disabled.noclick {
  cursor: default;
  opacity: 20%;
}
.disabled {
  opacity: 60%;
  padding-left: 1em;
  padding-top: 1em;
  font-weight: bold;
  cursor: pointer;
}
.highlight {
  opacity: 100%;
  padding-left: 1em;
  font-weight: bold;
}
.chartLegendWrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(255,255,255,0.85);
  margin-top: 69px;
  width: 100%;
}
.chartLegendHandle {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: -24px;
}

.chartLegendWrapper > .closed  {
  display: none;
  transition: opacity 1s ease-out;
  opacity: 0;
}
.chartLegendWrapper > .open  {
  display: block;
  transition: opacity 1.0s ease-in-out;
}
.chartLegend  ul{
  list-style: none;
  padding: 1rem;
  margin: auto;
  text-align: center;
  border-style: solid;
  border-color: #9999;
  border-width: 0px 1px 2px 1px;
  width: 96%;
  background-color: #eee4;
  display: flex;
  flex-wrap: wrap;
}
.chartLegend ul > li {
  text-align: left;
  cursor: pointer;
  flex: 1 0 25%;
}
.debugChart {
  position: absolute;
  right: 0;
  width: 400px;
}

.goals {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 20%;
  z-index: -1;
}

.graphInputs {
  width: 100%;
  margin-bottom: 0.2rem;
}

.measure-align {
  position: absolute;
  top: 0px;
  right: 0px;
}
.date-align {

}
.chartToolTipNetEnergy {
  display: none;
  border-radius: 8px;
  position: absolute;
  width: 145px;
  height: 120px;
}
.chartToolTipNetEnergy .chartTTBody {
  padding: 1.2em;
}

.chartToolTip {
  display: none;
  border-radius: 8px;
  position: fixed;
  width: 120px;
  height: 120px;
  pointer-events: none;
}
.chartTTHeader {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #0b235a;
  color: #fff;
  font-size: x-small;
  padding: 1em;
}
.chartTTSeries {
  color: #fff;
  font-size: 80%;
  padding: 0.2em;
  width: 90%;
  background: #f3b;
  border-radius: 3px;
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  text-shadow: 1px 1px 2px #111;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chartTTBody {
  padding: 1em;
  background: #fff;
  color:#0b235a;
  opacity: 85%;
  font-size: 80%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ttDate {}
.ttTime {}

.ttSeriesValue {
  font-weight: bold;
}
.ttMeasure {}
.ttpercentTotal {
  font-weight: bold;
}
.ttAllCircuitsValue {
  font-weight: bold;
}
.ttAllCircuits {
  margin-top: .4em;
  padding-top: .4em;
  border-top: #0b235a 1px solid;
  display: block;
  font-weight: bold;
}
.ttAllCircuitsMeasure {}

.chart-tabs {
  width: 100%;
  border-bottom: solid .1rem #6fbafb;
  margin-top: 1rem;
}
@media only screen and (min-width: 20px) and (max-width: 768px) {
  .live-left {
    min-width: 25%;
    flex-grow: 1;
    width: unset;
  }
  .live-right {
    font-size: .8rem;
    /* min-width: 33%; */
    padding-right: 0;
  }
  .alert-icon {
    font-size: 1.2rem;
    padding-bottom: .2rem;
  }
  .chart-tabs {
    font-size: .6rem;
  }
  .dateRangePicker {
    font-size: .7rem;
  }
  .unitPicker {
    font-size: .7rem;
    padding-right: 0.5rem;
  }
  .chartLegend {
    font-size: .7rem;
  }
 }
.chart-tab.highlight {
  background: #fff;
  cursor: default;
  color: var(--elevation-blue);
}
.chart-tab.highlight:after{
  content: " ";
  background-color: white;
  width: 100%;
  height: .1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;

}
.chart-tab {
  color: var(--elevation-light-blue);
  margin-left: 0.1rem;
  margin-right: .1rem;
  border-left: solid 1px #6fbafb;
  border-top: solid 1px #6fbafb;
  border-right: solid 1px #6fbafb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-left: .8rem;
  padding-top: .5rem;
  padding-right: .8rem;
  cursor: pointer;
  font-size: var(--font-size-xsm);
  position: relative;
  padding-bottom: .5rem;
  top: -.40rem;
  
}

path.domain {
  opacity: 20%
}

.tooltip-solar {
  display: none;
  position: absolute;
  width: 360px;
  pointer-events: none;
}
.tooltip-solar-label {
  color: #fff;
  font-size: 80%;
  padding: 0.2em;
  background: #f3b;
  border-radius: 3px;
  position: absolute;
  top: 24px;
  margin-left: 1rem;
  padding: .3rem;
  width: 80px
}
.label-consumption {
  background: #ff8726;
  left:0;
}
.label-production {
  background: #6dd152;
  left: 33%
}
.label-net {
  background: #0046c0;
  right: 0;
  margin-right: 1rem;
}
.tooltip-solar-measure {
  position: absolute;
}
.measure-consumption {
  left: 20px
}
.measure-production {
  left: 130px
}
.measure-grid_net {
  right: 40px
}
.tooltip-solar-body {
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: #fff;
  color: #0b235a;
  opacity: 85%;
  font-size: 80%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.xAxisText {
  color: #333;
}
.yAxisText {
  color: #333;
}
.y-axis line {
  stroke: #666;
  stroke-opacity: 0.4;
}
.y-axis .tick text {
  transform: translate(36px, 12px);
  color: #333;
}


.devmode {
  display: none;
  color: #0077;
  padding-left: 1rem;
}

.devmode-on .devmode{
  display: unset;
}


.qs-bullet {
  width: 0.8rem;
  height: 0.8rem;
  float: left;
  border-radius: 3px;
  border: solid 2px;
  margin-right: 0.5rem;
}

li.qs-off > .qs-bullet {
  background-color: #fff !important;
}

.chartLegend-select-all {
  float: right;
  cursor: pointer;
}
.chartLegend-deselect-all {
  float: right;
  cursor: pointer;
  margin-left: .5rem;
  margin-right: .5rem;
}


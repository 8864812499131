.widget-occupier {
  background: var(--elevation-white);
  margin: 0.7rem;
  padding: 1.25rem;
  border-radius: calc(var(--skinny-border) * 5);
  box-shadow:inset 0px 0px 1rem rgba(0,0,0,.05);
  color: var(--elevation-blue);
}
.alert-icon {
  font-size: 2rem;
  margin-bottom: -1rem;
}